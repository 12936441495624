
export default {
  data() {
    return {
      popupSuccess: false,
      options: [
        {
          name: "icon",
          label: "Ikona",
          type: "image",
          size: "20x20",
        },
        {
          name: "description",
          label: "Opis",
          type: "tipTap",
        },
      ],
    };
  },

  methods: {
    resetFormClosePopup() {
      const form = document.getElementById("contact-form");
      if (!form) return;
      form.reset();
      this.popupSuccess = false;
    },
  },
};
