
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      options: [
        {
          name: "image",
          label: "Slika",
          type: "image",
        },
      ],
      settings: {
        infinite: false,
        arrows: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              fade: true,
            },
          },
        ],
      },
    };
  },
};
