
export default {
  data() {
    return {
      isMounted: false,
      options: [
        {
          name: "image",
          label: "Slika",
          type: "image",
          size: "56x56",
        },
        {
          name: "title",
          label: "Naslov",
          type: "text",
        },
      ],
      optionsCheckbox: [
        {
          name: "hero",
          label: "Hero sekcija?",
          type: "checkbox",
        },
      ],
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    isHero() {
      if (this.isMounted) {
        let hero;
        if (
          this.$refs.isHero &&
          this.$refs.isHero.items &&
          this.$refs.isHero.items[0]
        ) {
          if (this.$refs.isHero.items[0].hero) {
            hero = true;
          } else hero = false;
        } else hero = false;

        return hero;
      }
    },
  },
};
