
import s0 from '~/.nuxt/gb-cms/sections/ContactForm'
import s1 from '~/.nuxt/gb-cms/sections/EducationExamples'
import s2 from '~/.nuxt/gb-cms/sections/EducationHero'
import s3 from '~/.nuxt/gb-cms/sections/EducationMethods'
import s4 from '~/.nuxt/gb-cms/sections/StemOutside'
import s5 from '~/.nuxt/gb-cms/sections/AboutProject'
import s6 from '~/.nuxt/gb-cms/sections/HeroSection'
import s7 from '~/.nuxt/gb-cms/sections/LatestNews'
import s8 from '~/.nuxt/gb-cms/sections/ProjectGoals'
import s9 from '~/.nuxt/gb-cms/sections/ProjectPartners'
import s10 from '~/.nuxt/gb-cms/sections/PrivacyPolicy'
import s11 from '~/.nuxt/gb-cms/sections/ProjectFinancingAndDuration'
import s12 from '~/.nuxt/gb-cms/sections/ResourcesAndMaterialsHero'
import s13 from '~/.nuxt/gb-cms/sections/ResourcesLinks'
import s14 from '~/.nuxt/gb-cms/sections/StemHero'
import s15 from '~/.nuxt/gb-cms/sections/StemHistory'
import s16 from '~/.nuxt/gb-cms/sections/StemImportance'
import s17 from '~/.nuxt/gb-cms/sections/StemTechnologies'

const sectionMapper = {
	'contact-form': s0,
	'education-examples': s1,
	'education-hero': s2,
	'education-methods': s3,
	'stem-outside': s4,
	'about-project': s5,
	'hero-section': s6,
	'latest-news': s7,
	'project-goals': s8,
	'project-partners': s9,
	'privacy-policy': s10,
	'project-financing-and-duration': s11,
	'resources-and-materials-hero': s12,
	'resources-links': s13,
	'stem-hero': s14,
	'stem-history': s15,
	'stem-importance': s16,
	'stem-technologies': s17
}

export default sectionMapper
