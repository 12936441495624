
export default {
  data() {
    return {
      options: [
        {
          name: "icon",
          label: "Ikona",
          type: "image",
        },
        {
          name: "reason",
          label: "Razlog",
          type: "text",
        },
      ],
    };
  },
};
