
export default {
  props: {
    imgName: {
      type: String,
      default: "",
    },
    left: {
      type: Boolean,
      default: true,
    },
  },
};
