import { render, staticRenderFns } from "./ProjectFinancingAndDuration.vue?vue&type=template&id=668c760f&scoped=true"
var script = {}
import style0 from "./ProjectFinancingAndDuration.vue?vue&type=style&index=0&id=668c760f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668c760f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default})
