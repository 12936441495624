
export default {
  data() {
    return {
      options: [
        {
          name: "title",
          label: "Naslov",
          type: "text",
        },
        {
          name: "description",
          label: "Opis",
          type: "tipTap",
        },
        {
          name: "image",
          label: "Slika",
          type: "image",
        },
      ],
    };
  },
};
