
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      options: [
        {
          name: "logo",
          label: "Logo",
          type: "image",
        },
      ],
      settings: {
        arrows: false,
        infinite: false,
        variableWidth: true,
      },
    };
  },
};
