
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      options: [
        {
          name: "image",
          label: "Slika",
          type: "image",
        },
        {
          name: "title",
          label: "Naslov",
          type: "text",
        },
        {
          name: "description",
          label: "Opis",
          type: "tipTap",
        },
      ],
      settings: {
        infinite: true,
        arrows: false,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 840,
            settings: {
              variableWidth: false,
              slidesToShow: 1,
              centerMode: true,
            },
          },
        ],
      },
    };
  },

  methods: {
    goToPrev() {
      if (this.$refs.slick) {
        this.$refs.slick.prev();
      }
    },
    goToNext() {
      if (this.$refs.slick) {
        this.$refs.slick.next();
      }
    },
  },
};
