import { render, staticRenderFns } from "./EducationExamples.vue?vue&type=template&id=366398af&scoped=true"
import script from "./EducationExamples.vue?vue&type=script&lang=js"
export * from "./EducationExamples.vue?vue&type=script&lang=js"
import style0 from "./EducationExamples.vue?vue&type=style&index=0&id=366398af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "366398af",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,EditorListItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js').default,UiIconsHexArrow: require('/home/node/app/components/ui/icons/hexArrow.vue').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
