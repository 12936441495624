
export default {
  data() {
    return {
      focus: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "label",
    },
    error: {
      type: [Boolean],
      default: false,
    },
    errorMsg: {
      type: [String],
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },

    textarea: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputClass() {
      let style;
      if (this.error) {
        style = "border-red-600 text-red-500";
      }

      if (!this.error && this.focus) {
        style = "border-secondary-FABD02";
      } else if (!this.error && !this.focus) {
        style = "border-secondary-E1E8ED";
      }

      return style;
    },
    inputLabel() {
      let labelText;
      if (this.required) {
        labelText = `${this.label}*`;
      } else {
        labelText = `${this.label}`;
      }

      return labelText;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
