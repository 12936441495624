
export default {
  data() {
    return {
      options: [
        {
          name: "title",
          label: "Naslov",
          type: "text",
        },
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
    };
  },
};
